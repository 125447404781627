@tailwind base;
@tailwind components;
@tailwind utilities;

@import "app";

*, ::before, ::after {
  box-sizing: border-box;
}

html, body {
  position: absolute;
  top: 0; left: 0;
  right: 0; bottom: 0;
  height: 100vh;

  .app-parent {
    @apply transition-colors duration-200;
  }
}
